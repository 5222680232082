import React, { useContext, Fragment, useState, useEffect } from "react"
import { navigate } from "gatsby"
import { Formik, Form } from "formik"
import Layout from "../Layout/Layout"
import Section from "elements/Section"
import Message from "elements/Message"
import Container from "layout/Container"
import ActionButtons from "elements/ActionButtons"

import { AppContext } from "../../context/AppContext"

import { generateFormField } from "elements/Form/services/form"
import { parseFormField } from "../../services/airtable"
import { formSchema } from "./utils/formSchema"
import { generateInitialValues } from "../../services/context"
import { getContextFromSession } from "../../context/services/context"

import {
  zendeskUploadFiles,
  generateUploadFiles,
} from "services/zendeskService"

const PersonalDetails = ({ pageContext, location }) => {
  const { state, dispatch } = useContext(AppContext)

  let currentContext = getContextFromSession()

  const [loading, setLoading] = useState(false)
  const [filesUploaded, setFilesUploaded] = useState(state.documents)
  const [isComponentMounted, setComponentMounted] = useState(false)
  let formFields = pageContext.formFields

  let selectedCoupon = state?.epharmacy?.coupon
  if (!selectedCoupon?.code?.startsWith?.("MGBESTLIFE")) {
    formFields = formFields?.filter(
      (formField) => !formField?.inclusions?.includes("Best Life")
    )
  }
  useEffect(() => {
    const isPartnerRequest = !!currentContext?.epharmacy?.hasPartnerRequest
    const isMedGrocerWellnessPatient = !!currentContext?.epharmacy
      ?.isMedGrocerWellnessPatient

    const hasRxRequiredMedicine = currentContext?.epharmacy?.medicines.some(
      (medicine) => !!medicine.rxRequired
    )

    if (
      (hasRxRequiredMedicine ||
        isPartnerRequest ||
        isMedGrocerWellnessPatient) &&
      currentContext?.documents?.length === 0
    ) {
      navigate("/epharmacy/upload")
    }
  }, [currentContext])

  const handleCheckSCPWDDocuments = () => {
    let hasSCPWDIDFront = false
    let hasSCPWDIDBack = false

    for (let i = 0; i < state?.documents?.length; i++) {
      if (state?.documents[i]?.type === "scpwdId_front") {
        hasSCPWDIDFront = true
      } else if (state?.documents[i]?.type === "scpwdId_back")
        hasSCPWDIDBack = true
    }

    let isButtonDisabled = !(hasSCPWDIDFront && hasSCPWDIDBack)

    return isButtonDisabled
  }

  const handleSubmit = async (values) => {
    setLoading(true)
    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        epharmacy: { ...state.epharmacy, ...values },
      },
    })
    setLoading(false)

    navigate(location?.state?.previousRoute || "/epharmacy/payment-details")
  }

  let initialValues = {
    ...generateInitialValues({ fields: formFields }),
    ...state?.epharmacy,
  }

  formFields = formFields.filter((field) => {
    if (state?.epharmacy?.coupon?.code !== "SCPWD") {
      return !field?.section.includes("Senior Citizen")
    } else {
      return field?.section
    }
  })

  let validationSchema = formSchema({ fields: formFields })
  let sectionFormFields = parseFormField(formFields)
  sectionFormFields = sectionFormFields.sort(
    (firstSection, secondSection) => firstSection.order - secondSection.order
  )

  useEffect(() => {
    setComponentMounted(true)
    let documentsWithNoToken =
      filesUploaded?.filter?.((document) => !document?.token)?.length || 0

    if (!state?.activeDocument && documentsWithNoToken > 0) {
      let currentDocumentWithNoToken = filesUploaded?.find?.(
        (document) => !document?.token
      )
      dispatch({
        type: "SAVE_ACTIVE_DOCUMENT",
        payload: currentDocumentWithNoToken,
      })
      uploadToZendesk({ currentDocument: currentDocumentWithNoToken })
    }
    return () => {
      setComponentMounted(false)
    }
  }, [filesUploaded])

  const uploadToZendesk = async ({ currentDocument }) => {
    const UPLOADED_FILES = await zendeskUploadFiles([currentDocument])
    const GENERATED_FILE_TOKEN = await generateUploadFiles(UPLOADED_FILES)

    let newTempDocument = {
      ...currentDocument,
      path: null,
      token: GENERATED_FILE_TOKEN?.[0],
    }

    dispatch({
      type: "REMOVE_ACTIVE_DOCUMENT",
      payload: {
        ...newTempDocument,
      },
    })
  }

  useEffect(() => {
    dispatch({ type: "GET_CONTEXT_FROM_SESSION" })
  }, [])

  return (
    <Layout
      title={pageContext?.module?.title}
      currentStep={3}
      totalSteps={4}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.seoTitle}
      display={{ footer: false, helpCenterBanner: false, radialStepper: true }}
      pageContext={pageContext}
    >
      <Container isCentered>
        <Formik
          enableReinitialize
          initialValues={{ ...initialValues }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, isValid, submitCount }) => (
            <Form>
              {sectionFormFields.map((section) => (
                <Section
                  title={section?.section}
                  subtitle={section?.subtitle || ""}
                  id={section?.sectionId || ""}
                  className="mt-1 mb-3"
                >
                  {section?.message && (
                    <Message color={section?.messageColor || "light"}>
                      <div
                        className="content"
                        dangerouslySetInnerHTML={{
                          __html: section?.message,
                        }}
                      />
                    </Message>
                  )}{" "}
                  {section?.fields.map((field) => {
                    if (!field?.referenceAnswer) {
                      return (
                        <Fragment>
                          {generateFormField({
                            setFilesUploaded,
                            formFields: section?.fields,
                            formField: field,
                            values,
                            setFieldValue,
                            context: {
                              state,
                              dispatch,
                            },
                          })}
                          {!!field?.addDividerAfterField && (
                            <hr className="has-background-light" />
                          )}
                        </Fragment>
                      )
                    }
                    return null
                  })}
                </Section>
              ))}
              {!isValid && submitCount > 0 && (
                <Message color="danger">
                  You may have missed some required fields. Please scan through
                  the form and check if your information is complete.
                </Message>
              )}

              <ActionButtons
                back={{
                  label: "Back",
                  link: pageContext?.backPath,
                  disabled:
                    state?.documents?.filter((document) => !document?.token)
                      ?.length > 0,
                }}
                submit={{
                  label: location?.state?.previousRoute
                    ? "Summary"
                    : "Payment Details",
                  loading,
                  disabled:
                    state?.epharmacy?.coupon?.code === "SCPWD"
                      ? handleCheckSCPWDDocuments()
                      : state?.documents?.filter((document) => !document?.token)
                          ?.length > 0,
                }}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default PersonalDetails
